import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import type { GuardianWeaponStats } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";

export default memo(function WeaponTopStatCards({
  weapons,
  profileImgUrl,
}: {
  weapons: GuardianWeaponStats["weaponAggregates"];
  profileImgUrl: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const bestStat = weapons.slice().sort((a, b) => b.kills - a.kills)?.[0];
  if (!bestStat) return null;
  const highlight = {
    label: bestStat.weapon.name,
    position: 1,
    stat: bestStat.kills.toLocaleString(language, {
      maximumFractionDigits: 0,
    }),
    statLabel: t("common:stats.kills", "Kills"),
    image: profileImgUrl,
    imageSize: 100,
  };
  const weaponSortedByKills = weapons
    .slice()
    .sort((a, b) => b.kills - a.kills)
    .slice(0, 20);
  const timeScaleData = weaponSortedByKills.map((i) => {
    const totalKills = i.kills;
    const precisionKillAvg = sanitizeNumber(i.precisionKills / i.count);
    const killAvg = sanitizeNumber(totalKills / i.count);
    return {
      key: i.weapon.name,
      tooltipTitle: <div>{i.weapon.name}</div>,
      stats: {
        kills: {
          value: totalKills,
          display: totalKills.toLocaleString(language),
        },
        precisionKills: {
          value: i.precisionKills,
          display: i.precisionKills.toLocaleString(language),
        },
        totalKills: {
          value: i.kills,
          display: i.kills.toLocaleString(language),
        },
        killAvg: {
          value: killAvg,
          display: killAvg.toLocaleString(language),
        },
        precisionKillAvg: {
          value: precisionKillAvg,
          display: precisionKillAvg.toLocaleString(language),
        },
        count: {
          value: i.count,
          display: i.count.toLocaleString(language),
        },
      },
    };
  });
  const avgs = {
    id: "avgs",
    label: ["val:avgKills", "Avg. Kills"] as Translation,
    position: 0,
    stat1Label: ["val:avgKills", "Avg. Kills"] as Translation,
    stat2Label: [
      "destiny2:stats.avgPrecisionKills",
      "Avg. Precision Kills",
    ] as Translation,
    color: "var(--red)",
    data: timeScaleData,
    xField: "totalKills",
    yField: "killAvg",
    y2Field: "precisionKillAvg",
    hidePosition: true,
  };
  const kills = {
    id: "kills",
    label: ["common:stats.kills", "Kills"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kills", "Kills"] as Translation,
    stat2Label: ["common:precisionKills", "Precision Kills"] as Translation,
    color: "var(--orange)",
    data: timeScaleData,
    xField: "totalKills",
    yField: "kills",
    y2Field: "precisionKills",
    hidePosition: true,
  };
  return (
    <GridContainer>
      {React.createElement(HighlightStatCard, highlight)}
      {React.createElement(TimescaleChart, kills)}
      {React.createElement(TimescaleChart, avgs)}
    </GridContainer>
  );
});

const GridContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);
  .avatar-container .avatar {
    border-radius: 100%;
    width: 66px;
    top: -7px;
    z-index: -1;
  }
`;
